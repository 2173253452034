.tabs-container .tab-list {
    display: flex;
    margin-top: 20px; 
    border: 1px solid #e0e0e0;
    border-radius: 8px 8px 0 0;
}
  
.tabs-container .tab-item {
    margin-right: 12px;
    padding: 8px 16px; 
    border-color: #ea580c;
    cursor: pointer; 
}
  
.tabs-container .tab-item:hover {
    color: #ea580c;
}

.tabs-container .tab-item:focus {
    outline: none;
}
  
.tabs-container .selected-tab {
    border-bottom: 2px solid #ea580c;
    color: #ea580c;
}
  
.tabs-container .tab-panel {
    padding: 16px;
    border-radius: 0 0 8px 8px;
    border: 1px solid #e0e0e0;
}

.tabs-container .react-tabs__tab-panel:not(.react-tabs__tab-panel--selected) {
    display: none;
}

