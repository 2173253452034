  .mappings-container {
    height: 500px;
    overflow-y: auto;
  }
  
  .table-container {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  .table-container th {
    background-color: #f4f4f4;
    border-bottom: 1px solid white;
  }

  .table-container thead {
    background-color: #fafafa;
  }

  .table-container tr {
    height: 54px;
  }

  .table-container tr:nth-child(odd) {
    background-color: #fafafa;
  }
  
  .table-container th, .table-container td {
    text-align: left;   
    padding: 8px;
  }
  
  .table-container td {
    border-bottom: 1px solid #ddd;
  }
